.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial; // check absolute later
    height: 100%;
    overflow: auto;


    h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
    }

    // 
    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }
    // == horizontal image ==
    .image-box {
        position: relative;
        // flex: 1 1 20%; 
        width: 100%; /* stretch to full width */
        height: 100%; /* stretch to full height */
        height: 400px; 
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(70% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            display: flex;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        // == Text Background ==
        .content {
            position: absolute; // position on top of the image
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px; // bottom text portfolio
        }
        // == TITLE ==
        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }
        // == DESCRIPTION ==
        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        // == BUTTON ==
        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffe8d6;
            border-radius: 4px;
            font-size: 14px;
            color: #ffe8d6;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700; // font bold
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }
        // background color when hover
        .btn:hover {
            transform: translateY(-3px);
            background: #D3D3D3;
        }

        // text overlay
        &:after {
            content: "";
            background: linear-gradient(180deg, #D3D3D3, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }


    }

}

// == MOBILE RESPONSIVE == //

@media screen and (max-width: 1200px) {
    .container.portfolio-page {
        width: 100%;
        padding: 20px;
        box-sizing:border-box; // no horizontal scroll bar


        h1.page-title {
           margin-left: 20px;
           margin-top: 20px; 
        }

        .image-box {
            height: 200px;
            max-width: calc(50% - 10px);
        }

    }

}