html {
  font-size: 62.5%;
}



body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  /* color: #444; */
  
  background: linear-gradient(to right, #283048, #859398) !important; 
  width: 100vw;
  height: 100vh;

    /* background: #6b705c; */
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Mobile responsive */
@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}