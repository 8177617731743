.home-page {
    
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%; 

    }

    //  h1 inside the <body> 
    h1 {
        color: #ede0d4;
        font-size: 60px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #cb997e;
            font-size: 20px;
            position:absolute;
            margin-top: -40px;
            left: 15px;
            // opacity: 0.6;
            animation: fadeIn 1s 1.7s backwards;
        }

        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #cb997e;
            font-size: 20px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;

        }
        // == small M  ==
        img {
            width: 80px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
            object-position: 1px 15px;
            
            
        }
    }

    // Frontend Developer / Business Analyst
    h2 {
        color: #d6cfcf;
        margin-top: 20px;
        font-weight:400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    // CONTACT BUTTON 
    .flat-button {
        color: #ffe8d6;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffe8d6;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: no-wrap;
        border-radius: 4px;
        &:hover {
            background: #ffe8d6;
            color:#333;
        }
    }
}

// === MOBILE RESPONSIVE == //
@media screen and (max-width: 1200px) {
    .tags, //  REMOVE the <h1> tags
    .home-page h1:before,
    .home-page h1:after {
      display: none;
    }

    .home-page .text-zone {
        position: initial;
        width: 100%;
        transform: none;
        padding: 10px;
        box-sizing: border-box;
      }


    .flat-button {
        float: none;
        display: block;
        margin: 20px auto 0 auto;
        width: 125px;
    }







    //   hiding the LOGO. NO  M ICON 
    // .logo-container {
    //     position: relative;
    //     width: 100px;
    //     height: auto;
    //     top: 50px;
    //     right: 0;
    //     box-sizing: border-box;
    //     margin: auto;
    //     left: 0;
    //   }

    .logo-container svg { //  M animation
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
      }

}