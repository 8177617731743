.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 7%;
    // padding-bottom: 10%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;

    
}
//  Spinning animation
.cubespinner {
    animation-name: spincube; // CUSTOM ANIMATION  below @keyframes
    animation-timing-function:ease-in-out;
    animation-iteration-count: infinite;
    animation-duration:  12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: 100px; // WIDTH of the cube
    
    
    

    div {
        position:absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightgoldenrodyellow;
        
    }
    
    .face1 {
        transform: translateZ(100px);
        color: #F06529;
    }
    .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #28A4D9;
    }
    .face3 {
        transform: rotateY(90deg)  rotateX(90deg) translateZ(100px);
        color: #EFD81D;
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #EC4D28;
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg)translateZ(100px);
        
    }
    .face6 {
        transform: rotateX(-9090deg) translateZ(100px);
        color: #5ED4F4;
    }

}

// Define Animation  
@keyframes spincube {
    from,
    //  Starting  point of the animation
    to {
        transform:rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
        transform: rotateY(-90deg);
    }
    33% {
        transform: rotate(-90deg) rotateZ(90deg);

    }
    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
        transform: rotateX(90deg);
    }
}

// == MOBILE RESPONSIVE FOR CUBE ANIMATION ==
@media screen and (max-width: 1200px) {
    .stage-cube-cont {
        position: initial;
        width: 100%;
        height: 0%;
        overflow: visible;
    }
}