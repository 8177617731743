// Define global styles
$primary-color: #cb997e; // inside of the animate.css library

@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';


// Using 3 FONTS 

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

//  default and text areas
input,
textarea {
  font-family: 'Helvetica Neue';
}

// ==== LOADING for ABOUT ME PAGE ===
.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}