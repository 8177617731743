
.contact-form {
    width: 100%;
    margin-top: 375px;
  
}

    ul {
        padding: 0;
        margin: 0;
        

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            opacity: 0;
            overflow: hidden;
            display: block;
            clear:both;
            position:relative;
            animation: relative;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
        }

        // half column Name & Subject
        li.half {
            width: 49%;
            margin-left: 2%;
            float:left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
        // Target input type text and email 
        input[type='text'],
        input[type='email'] {
            width: 100%;
            border: 0;
            border-radius: 4px;
            background: #859398;
            height: 50px;
            font-size:  16px;
            color: #ffffff;
            padding: 0 20px;
            box-sizing: border-box;
        }

        //  MESSAGE
        textarea {
            width: 100%;
            border: 0;
            border-radius: 4px;
            background: #859398;
            height: 50px;
            font-size: 16px;
            color: #fff;
            padding: 20px;
            box-sizing: border-box;
            min-height: 150px;
        }
        // SEND
        .flat-button {
            color: #ffe8d6;
            font-size: 11px;
            letter-spacing: 3px;
            text-decoration: none;
            padding: 8px 10px;
            border: 1px solid #ffe8d6;
            float: left;
            border-radius: 4px;
            background: 0 0;
            text-transform: uppercase;
            float: right;
            text-align: center;
            margin-right: 10px;
        }
    }
// ==== INFO  MAP 
 .info-map {
    position: absolute;
    background: #000;
    top: 50px;
    right: 40%;
    z-index: 999999; // on top of everything
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards; // box will remain visible

    // EMAIL
    span {
        font-size: 16px ;
        display: block;
        padding-top: 20px;
        color: #cb997e;
        
    }
 }

//  === MAP 
.map-wrap {
    background: rgba(8, 253,216,0.1);
    float: right;
    display: flex;
    width: 53%;
    height: 115%;
    margin-top: auto;
    
}


 .leaflet-container {
     position:relative;
     width: 100%;
     height: 100%;
     opacity: 0;
     animation: backInRight 1s 1.2s;
     animation-fill-mode:forwards;
     

 }


 // == MOBILE RESPONSIVE ==
 @media screen and (max-width: 1200px) {
    .map-wrap {
        // float: none;
        width: 100%;
        height: 100px;
        margin: 0;
        }

    .info-map {
        display: none;

    }

    // SEND BUTTON
    .contact-form li input.flat-button {
        float: none;
        display: block;
        margin: 20px auto 0 auto;
        width: 70px;
        text-align: center;
      }

    
}