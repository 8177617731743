.nav-bar {
    background: #181818;
    width: 100px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;
}

.logo {
    display: block;
    padding: 8px 0;
    height: auto;

// LOGO
    img {
        display: block;
        margin: 8px auto;
        width: 90px;
        height: auto;
        
// SUB LOGO - MI-ANN -
        &.sub-logo {
            width: 90px;
            
        }
    }
}

nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    // ICONS 
   a {
    font-size: 35px;
    color: #4d4d4e;
    display: block;
    line-height: 65px;
    height: 65px;
    position: relative;
    text-decoration: none;

    i {
       transition: all 0.3s ease-out; 
    }
    &:hover {
        color: #ceccbd;

        svg {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
    }
    // all of the elements
   &:after {
       content: '';
       font-size: 17px;
       letter-spacing: 2px;
       position: absolute;
       bottom: 0;
       display: block;
       width: 100%;
       text-align: center;
       opacity: 0;
       transition: all 0.3s ease-out;  //for smooth transition 
    }
//   === HOME === //Text will appear when hover
    &:first-child {
        &:after {
            content: 'HOME';
        }
     }
   }

// === ABOUT === Text will appear when hover
   a.about-link {
       &:after {
           content: 'ABOUT';
       }
   }
// === CONTACT === Text will appear when hover
   a.contact-link {
       &:after {
           content: 'CONTACT'
       }
   }
// === PORTFOLIO === Text will appear when hover
a.portfolio-link {
    &:after {
        content: 'PORTFOLIO'
    }
}



// HOME ICON WITH WHITE COLOR
   a.active {
       svg {
           color: #d5d5d5;
       }
    }
 }
// LINKEDIN & GITHUB
 ul {
     position: absolute;
     bottom: 20px;
     width: 100%;
     display: block;
     padding: 0;
     list-style: none;
     text-align: center;
     margin: 0;

     li {
         a {
             padding: 7px 0;
             display: block;
             font-size: 25px;
             line-height: 16px;

             &:hover svg {
                color: #ceccbd;
             }  
         }
     }
 }

 .hamburger-icon, .close-icon {
    display: none; // on the larger screen
 }

// === MOBILE RESPONSIVE ==

@media screen and (max-width: 1200px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;

        ul, nav {
            display: none;
           }

        nav {
            width: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(to right, #283048, #859398) !important;
            height: 100%;
            margin: 0;
            z-index: 2;
            position: fixed;
        


        &.mobile-show {
            display: block;
        }

        
        a {
            display: flex;
            justify-content: center;
            align-items: center;
          }

        a:after {
            opacity: 1;
            position: initial;
            width: auto;
            margin-left: 10px;
          }

        a svg {
            opacity: 1 !important;
          }
        }
        .hamburger-icon,
        .close-icon {
            display: block; // to show the burger menu when screen is shrink.
            position: absolute;
            top: 15px;
            right: 15px;
            
          }


    }

    
  }
