
//  HTML & Body Tags 
.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ceccbd;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 160px;
    font-size: 20px;
    font-family: 'La Belle Aurore';
}
//  Html and Body Tag 
.bottom-tag-html {
    margin-left: -20px;
}

//  Big M icon animation
.container {
    width: 150%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0.1;
    top: 5%;
    margin: 0 auto;
    z-index: 2;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s ;
}
// == ABOUT ME & CONTACT PAGE ==
.contact-page,
.about-page,
.portfolio-page {
    

        h1 {
          font-size: 53px;  
          font-family: 'Coolvetica';
          color: #ede0d4 ;
          font-weight: 400;
          margin-top: 0;
          position: relative;
          margin-bottom: 40px;
          left: -10px;

        
        
        // <H1> body tag 
        &:before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #cb997e;
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            // opacity: 0.6px;
            line-height: 18px;
        }
        // </h1> tag in 
        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #cb997e;
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            left: -30px;
            bottom: -20px;
            margin-left: 20px;
            // opacity: 0.6;
      }
    }
    
    
    p {
        font-size: 15px;
        color: #fff;
        font-family:  sans-serif;
        font-weight: 500;
        max-width: fit-content;
        animation: pulse 1s;

        // 1st paragraph
        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }
        //  2nd paragraph
        &:nth-of-type(2) {
            animation-delay: 1.2s;
        }
        // 3rd paragraph
        &:nth-of-type(3) {
            animation-delay: 1.3s;
        }
    }
}

.text-zone {
    position: absolute;
    left: 10%;
    top:50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: table-cell;
    max-height: 90%;

    .text-animate-hover {
        &:hover {
            color: #fff
        }
    }
}

// === MOBILE RESPONSIVE ==//
@media screen and (max-width: 1200px) {
    
    
    .page { // every single page 
        position: initial; 
    }

    .container { // this will fix a lot of bugs, it will apply to all pages
        position: initial;
        height: auto;
        min-height: auto;


        &.contact-page,
        &.about-page,
        &.portfolio-page {
          .text-zone {
            position: initial;
            transform: none;
            width: 100%;
            display: block;
            padding: 20px;
            box-sizing: border-box;
            overflow-x: hidden;
          }
    }

   }
}